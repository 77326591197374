import {
  getCourseLesson,
  hideAsideNav,
  lessonsSelectors,
  setLessonQuizCompleted,
} from "../../../../redux/courseSlice";
import {
  selectAnswerIsLoading,
  selectCoursePosition,
  selectCourseQuizeDetail,
  selectCurrentOpenPageInQuiz,
} from "../../redux/Quze/courseQuizeSelectors";
import {
  clearAttemptStatus,
  getQuize,
  getQuizeResult,
  setQuizeComplite,
  setQuizeFinish,
} from "../../redux/Quze/courseQuizeSlice";
import { useDebounceFn, useUnmount } from "ahooks";
import {
  clearAnswersOfAttempt,
  clearCurrentOpenPageinQuiz,
  getQuizeQuestionAnswers,
  setAnswerIsLoading,
  setCurrentOpenPageInQuiz,
  setQuizeQuestionAnswer,
} from "../../redux/Quze/courseQuizeQuestionsSlice";
import { useEffect, useRef, useState } from "react";

import { getQuizeQuestions, questionsSelectors } from "../../redux/Quze/courseQuizeQuestionsSlice";

import { EntityId } from "@reduxjs/toolkit/src/entities/models";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../../hooks/redux";

export const useCourseTestQuestions = () => {
  const dispatch = useAppDispatch();

  const questions = useAppSelector(questionsSelectors.selectAll);
  const quizeDetail = useAppSelector(selectCourseQuizeDetail);
  const position = useAppSelector(selectCoursePosition);
  const currentOpenPageInQuiz = useAppSelector(selectCurrentOpenPageInQuiz);
  const answerIsLoading = useAppSelector(selectAnswerIsLoading);

  const countPages = position === 0 ? 0 : Math.ceil(questions.length / position);

  const debouncedQuestionRef = useRef<{ [id: string | number]: () => unknown }>({});
  const [resolveLoadingPromise, setResolveLoadingPromise] = useState<() => void | null>();

  const { lesson_id } = useParams();
  const lesson = useAppSelector((state) =>
    lessonsSelectors.selectById(state, lesson_id as EntityId)
  );

  const handlePrevQuest = () => {
    if (currentOpenPageInQuiz > 0) {
      dispatch(setCurrentOpenPageInQuiz(currentOpenPageInQuiz - 1));
    }
  };

  const handleNextQuest = () => {
    if (currentOpenPageInQuiz < countPages) {
      dispatch(setCurrentOpenPageInQuiz(currentOpenPageInQuiz + 1));
    }
  };

  const waitForQuizAnswerToLoad = () => {
    return new Promise<void>((resolve) => {
      if (!answerIsLoading) {
        resolve();
      } else {
        setResolveLoadingPromise(() => resolve);
      }
    });
  };

  const handleDoneQuest = async () => {
    // Ждем, пока quizeAnswerIsLoading станет false
    await waitForQuizAnswerToLoad();

    // Обрабатываем вопросы
    for (const id in debouncedQuestionRef.current) {
      await debouncedQuestionRef.current[id]();
    }

    dispatch(setQuizeFinish(lesson_id))
      .unwrap()
      .then(() => {
        dispatch(clearAttemptStatus());
        dispatch(getQuizeResult(lesson_id));
        dispatch(getQuize(lesson_id));
        dispatch(setQuizeComplite());
        return dispatch(getCourseLesson(lesson_id)).unwrap();
      })
      .then((x) => {
        if (x.status === "completed") {
          dispatch(setLessonQuizCompleted(lesson_id));
        }
      });
  };

  const { run: handleChange } = useDebounceFn<
    (option: {
      quizeId: string | number;
      questionId: string | number;
      attemptId: number;
      answer: any;
    }) => void
  >(
    (option) => {
      dispatch(setQuizeQuestionAnswer(option)).finally(() => dispatch(setAnswerIsLoading(false)));
    },
    {
      wait: 1000,
    }
  );

  const handleAnswerChange = (option: {
    quizeId: string | number;
    questionId: string | number;
    attemptId: number;
    answer: any;
  }) => {
    dispatch(setAnswerIsLoading(true));
    handleChange(option);
  };

  useEffect(() => {
    if (quizeDetail?.attempt_id && quizeDetail?.attempt_status === "started") {
      dispatch(
        getQuizeQuestionAnswers({
          quizeId: quizeDetail?.id ?? 0,
          attemptId: quizeDetail.attempt_id ?? 0,
        })
      );
    }
    dispatch(getQuizeQuestions(+quizeDetail?.id)).then(() => {
      dispatch(hideAsideNav());
    });
  }, [dispatch, quizeDetail.attempt_id, quizeDetail?.attempt_status, quizeDetail?.id]);

  useEffect(() => {
    if (!answerIsLoading && resolveLoadingPromise) {
      resolveLoadingPromise();
      setResolveLoadingPromise(() => null);
    }
  }, [answerIsLoading, resolveLoadingPromise]);

  useEffect(
    () => () => {
      dispatch(clearCurrentOpenPageinQuiz());
    },
    [dispatch]
  );

  useUnmount(() => {
    dispatch(clearAnswersOfAttempt());
  });

  return {
    quizeDetail,
    lesson,
    handleDoneQuest,
    questions,
    countPages,
    lesson_id,
    currentOpenPageInQuiz,
    handleNextQuest,
    handlePrevQuest,
    handleChange: handleAnswerChange,
  };
};
