import { Select } from "../../../../../components/Select";
import { Option } from "../../../../../components/Select/types";
import styles from "../RewardsSearch.module.scss";

const ProductTypes = [
  {
    label: "Все",
    value: "",
  },
  {
    label: "Товары",
    value: "Product",
  },
  {
    label: "Услуги",
    value: "Service",
  },
];

interface TypeSearchProps {
  selectedType: string;
  onChange: (value: string | undefined) => void;
}

export const TypeSearch = ({ selectedType, onChange }: TypeSearchProps) => {
  return (
    <div className={styles["primary-control"]}>
      <Select
        showCleaner={!!selectedType}
        label="Тип"
        onChange={(opt) => onChange?.((opt as Option)?.value)}
        value={ProductTypes.find((x) => x.value === selectedType)}
        options={ProductTypes}
      />
    </div>
  );
};
