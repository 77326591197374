import { CompetenciesCard } from "components/CompetenciesCard";
import {
  СompetenciesType,
  СompetenciesTypeData,
} from "components/CompetenciesCard/competancies.types";
import { H3 } from "components/Typography/Titles";
import styles from "./CourseResultCompetenciesList.module.scss";

interface CourseResultCompetenciesListProps {
  competences: СompetenciesTypeData | [];
}

export const CourseResultCompetenciesList = ({
  competences,
}: CourseResultCompetenciesListProps): JSX.Element => (
  <div className={styles["list"]}>
    <H3 className={styles["achievements__title"]}>Вы развили компетенции</H3>
    {competences.map((competence: СompetenciesType) => (
      <CompetenciesCard
        competence={competence}
        key={competence.id}
        className={styles["competence"]}
      />
    ))}
  </div>
);
