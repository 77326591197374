import { AdminIcon } from "components/Icons";
import { EducationIcon } from "../../../../../../components/Icons/EducationIcon/EducationIcon";
import classNames from "classnames";
import styles from "./SelectPanelButton.module.scss";

interface SelectPanelButtonPops {
  type: "webapp" | "admin";
  onClick: () => void;
}

const buttonConfig = {
  webapp: {
    icon: <EducationIcon />,
    title: "Обучение",
    description: "Прохождение курсов и заданий",
  },
  admin: {
    icon: <AdminIcon />,
    title: "Администрирование",
    description: "Управление курсами и пользователями",
  },
};

export const SelectPanelButton = ({ type, onClick }: SelectPanelButtonPops): JSX.Element => {
  const myAcademyTheme = process.env.REACT_APP_THEME === "myAcademy";

  return (
    <div
      className={classNames(styles["wrapper"], {
        [styles["myAcademy"]]: myAcademyTheme,
      })}
      onClick={onClick}
    >
      {buttonConfig[type].icon}
      <div className={styles["text"]}>
        <span className={styles["text-title"]}>{buttonConfig[type].title}</span>
        <span className={styles["text-description"]}>{buttonConfig[type].description}</span>
      </div>
    </div>
  );
};
