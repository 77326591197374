import { WebAppRoutes } from "app/routes";
import { Button } from "components/Buttons";
import { format } from "date-fns";
import { useNavigate } from "react-router";
import {
  convertBlobToBase64,
  extractMimeType,
  normalizeISODate,
  numWord,
} from "../../../../../app/utils";
import { TestResultStarIcon } from "../../../../../components/Svg/Icons";
import {
  TestResultLeftDraw,
  TestResultLeftLeafPlugUnfilled,
  TestResultRightDraw,
  TestResultRightLeafPlugUnfilled,
} from "../../../../../components/Svg/Pics";
import { H1 } from "../../../../../components/Typography/Titles";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/redux";
import {
  courseCertificateDownloadAvailability,
  selectCourseIsFinish,
  selectCourseMenuIsOpen,
  selectCourseResultDays,
  selectCourseResultGrade,
} from "../../../redux/courseSelectors";
import { ICourse } from "../../../redux/interfaces";
import { ResultBlockProcessing } from "./ResultBlockProcessing";
import { CertificateSvgImage } from "components/svg-images";
import { DownloadIcon } from "components/Icons";
import { useEffect } from "react";
import { getCertificateDownloadAvailability } from "pages/Course/redux/courseSlice";
import { RoundedButton } from "./RoundedButton";
import { useMediaQuery } from "hooks";
import { Capacitor } from "@capacitor/core";
import classNames from "classnames";
import api from "services/api/api";

export const ResultBlock = ({ course }: { course: ICourse }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isMobile = useMediaQuery("(max-width: 550px)");

  const courseResultDays = useAppSelector(selectCourseResultDays);
  const courseResultGrade = useAppSelector(selectCourseResultGrade);
  const courseResultIsFinish = useAppSelector(selectCourseIsFinish);
  const certificateDownloadAvailability = useAppSelector(courseCertificateDownloadAvailability);
  const courseMenuIsOpen = useAppSelector(selectCourseMenuIsOpen);

  const finishedTs =
    course?.finished_ts && format(new Date(course.finished_ts * 1000), "yyyy-MM-dd");

  const courseStart =
    course?.enrolled_ts && format(new Date(course.enrolled_ts * 1000), "yyyy-MM-dd");

  const COURSE_FEEDBACK_LINK = `${WebAppRoutes.COURSE}/${course?.id}/feedback`;

  const trainingDates = (startDt: string | null, endDt: string | null) => {
    let date = "";
    date += startDt ? normalizeISODate(courseStart) : "";
    date += endDt ? " - " + normalizeISODate(endDt) : "";

    return date;
  };

  const courseDate = trainingDates(courseStart || "", finishedTs || "");

  const handleFeedBackClick = () => {
    navigate(COURSE_FEEDBACK_LINK);
  };

  const handleDownloadCertificate = async () => {
    try {
      const url = `/certificate/${course.id}/generate?theme=${
        process.env.REACT_APP_THEME === "myAcademy" ? "lanit" : "es"
      }`;

      const response = await api.get(url, {
        responseType: "blob",
      });

      const blob = response.data;

      const downloadUrl = window.URL.createObjectURL(blob);

      if (Capacitor.isNativePlatform()) {
        const base64FromBlob = await convertBlobToBase64(blob);
        const { base64, mimeType } = extractMimeType(base64FromBlob);
        (window as any).PreviewAnyFile.previewBase64(
          (win: any) => console.log("open status", win),
          (error: any) => console.error("open failed", error),
          base64,
          {
            mimeType,
          }
        );
        return;
      }

      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = "certificate.pdf";
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("Ошибка загрузки сертификата: ", error);
    }
  };

  useEffect(() => {
    course?.id && dispatch(getCertificateDownloadAvailability(course.id));
  }, [dispatch, course]);
  if (courseResultIsFinish) {
    return (
      <div className="test-result-card test-result-card_course">
        <div className="test-result-header">
          <H1 className="test-result-card__title">Завершено обучение</H1>
          <span
            className={`test-result-card__date ${
              process.env.REACT_APP_THEME === "myAcademy" ? "test-result-card__myAcademy-date" : ""
            }`}
          >
            {courseDate}
          </span>
        </div>
        <div className="test-result-raiting test-result-raiting-plug">
          <div
            className="test-result-raiting-block test-result-raiting-block-plug"
            style={{ ...(isMobile ? { paddingTop: 0 } : {}) }}
          >
            <div
              className={`test-result-raiting__pretitle-container ${
                courseMenuIsOpen ? "narrow-container" : ""
              }`}
            >
              <div className="test-result-raiting__pretitle-container-score">
                <TestResultLeftDraw className="test-result-raiting-draw test-result-rating-draw-left" />
                <TestResultLeftLeafPlugUnfilled
                  className={classNames("test-result-raiting-leaf test-result-raiting-leaf-left", {
                    "test-result-raiting-leaf-left-ios":
                      (Capacitor.getPlatform() === "ios" && Capacitor.isNativePlatform()) ||
                      isMobile,
                  })}
                />

                <div className="test-result-raiting__title_block">
                  {courseResultGrade === 0 ? (
                    <div className="test-result-raiting__title-container">
                      <p className="test-result-raiting__pretitle">Курс завершен</p>
                    </div>
                  ) : (
                    <div className="test-result-raiting__title-container">
                      <p className="test-result-raiting__pretitle">Итоговая оценка</p>
                      <p className="test-result-raiting__title">{courseResultGrade}%</p>
                    </div>
                  )}
                </div>

                <TestResultRightLeafPlugUnfilled
                  className={classNames("test-result-raiting-leaf test-result-raiting-leaf-right", {
                    "test-result-raiting-leaf-right-ios":
                      (Capacitor.getPlatform() === "ios" && Capacitor.isNativePlatform()) ||
                      isMobile,
                  })}
                />
                <TestResultRightDraw className="test-result-raiting-draw test-result-rating-draw-right" />
              </div>

              {certificateDownloadAvailability && (
                <div className="test-result-raiting__certificate_block">
                  <CertificateSvgImage
                    color={process.env.REACT_APP_THEME === "myAcademy" ? "#E7F7FF" : "#F0EEFF"}
                    className="test-result-raiting__certificate_block-certificate"
                  />
                  <RoundedButton onClick={handleDownloadCertificate}>
                    <DownloadIcon />
                  </RoundedButton>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="test-result-card-block">
          <div className="test-result-card-info">
            <ul className="test-result-card-info-list">
              {(courseResultDays || 0) > 0 && (
                <li className="test-result-card-info-list__item">
                  <TestResultStarIcon className="test-result-card__icon" />
                  <p>
                    Время прохождения курса: <span>{courseResultDays} </span>
                    {numWord(courseResultDays, ["день", "дня", "дней"])}
                  </p>
                </li>
              )}
            </ul>
          </div>

          {course.commenting && (
            <Button
              className="test-result-card-button"
              onClick={handleFeedBackClick}
              color="primary"
            >
              Оцените этот курс
            </Button>
          )}
        </div>
      </div>
    );
  }

  return <ResultBlockProcessing />;
};
