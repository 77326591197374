import { SVGProps } from "react";

export const ChairLampIllustration = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 315 317"
    width={315}
    height={317}
    fill="none"
    className="svg"
    {...props}
  >
    <rect
      width={150.576}
      height={150.576}
      x={111.77}
      y={93.953}
      fill="#E8EAEF"
      rx={20}
      transform="rotate(15 111.77 93.953)"
    />
    <path
      stroke="#191229"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M119.634 213.255c-1.94 3.88-3.829 15.482 2.52 26.276 7.936 13.491 36.507 17.46 48.411 6.613 11.905-10.846 3.968-17.46 12.963-38.359 8.994-20.899 37.063-26.68 44.972-14.814 6.878 10.319-5.555 16.931-11.904 21.427-6.349 4.496-20.105 8.731-26.19 3.44-5.291-4.601-3.257-16.666 4.762-19.577 8.019-2.911 14.273 4.68 11.766 14.021-1.577 5.876-13.679 41.365-17.851 46.296-4.171 4.93-.483 30.422-5.555 30.422-6.085 0-3.439-23.28-5.82-21.692-2.381 1.587-16.402 1.984-25.661 1.058-8.923-.892-21.986-4.106-29.986-6.307-2.35-.647-4.688.271-5.471 2.579-1.903 5.612-4.41 15.006-8.457 14.309-7.672-1.322 3.968-21.958 4.232-25.926.265-3.968-15.343-46.295-9.788-54.76 5.556-8.466 14.548-4.484 17.46-1.057 4.498 5.292 5.417 16.4-4.371 17.194-9.788.793-18.782-18.599 0-24.155 18.783-5.555 23.683-5.474 27.916-1.77 13.756 12.037-14.286 36.772-16.137 32.539-1.277-2.919 12.364-7.796 15.948-8.73 6.126-1.597 21.66-2.635 36.557-.329"
    />
    <path
      stroke="#191229"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M141.463 182.917c4.497-12.697-8.73-21.957-5.027-34.126 3.704-12.169 14.55-15.608 25.925-16.402 11.376-.793 41.534-2.645 59.523 8.995 12.296 7.956 3.301 25.05 0 31.481-2.03 3.953-1.99 12.087-1.99 12.087M190.139 266.779c3.968-1.588 14.02-8.466 16.93-7.143 2.91 1.323 1.852 8.994 4.498 12.962 2.645 3.968 4.232-1.587 2.91-8.73-1.323-7.142-2.91-14.285 1.058-24.867 3.174-8.465 8.239-25.037 9.914-30.769M301.947 254.179c-5.736 2.837-27.123 2.323-30.779-1.292-5.477-5.417 9.654-11.535 17.476-10.831 8.609.774 20.085 8.77 13.303 12.123Z"
    />
    <path
      stroke="#191229"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M288.212 242c5.806-11.655 13.195-58.275 10.028-90.592-3.167-32.316-18.738-85.557-72.838-109.398-12.665-5.582-38.028-11.39-47.532-11.39-2.636 0-10.827-.125-14.278.138 3.965-4.29 9.759-10.646 19.519.375 7.917 6.606 19.071 12.996 23.821 27.3 4.751 14.304-62.019 16.796-64.658 3.022-2.639-13.774 14.541-25.993 18.237-27.69M154 74l-11 11M174.399 75.714l-.16 13.734M196.04 73.048l9.809 11.465M61.942 212.778C56.35 215.3 42.812 215.86 37 210.219c10.389-5.536 25.34-2.365 29.52-1.843 4.18.522 2.304 9.906 0 19.574-2.613 10.962-3.658 13.05-12.8 13.05-9.144 0-10.244-2.895-13.324-13.05C37.784 219.337 37 216.316 37 216.316"
    />
    <path
      stroke="#191229"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M50.506 207c-.529-.975-1.184-11.671-1.184-16.093-7.581 2.276-20.201 2.37-24.084-7.383 10.678-3.901 16.179.65 24.084 1.626-.753-3.036 0-17.662 0-20.497-3.765.759-12.435.668-20.525-2.583-8.045-3.233-16.566-12.897-17.797-17.654 12.153-6.124 29.473 10.896 40.33 15.684 1.254-2.783-6.842-8.794-14.12-19.165-7.277-10.372-4.2-29.909-2.695-28.897 1.506 1.012 7.278 2.529 12.799 18.466 5.52 15.936 2.51 16.695 2.008 13.912-.502-2.782 1.003-8.854 5.019-16.695 4.015-7.842 12.799-15.684 13.551-14.672 1.03 14.672-6.227 24.703-9.787 29.579-2.614 3.582-4.054 13.339 0 8.365 9.788-12.005 21.819-16.695 37.895-16.695-3.764 8.916-14.807 17.96-25.598 22.513-10.791 4.553-14.054 1.012-10.54 1.518 3.513.506 48.435 13.154 32.374 15.683-16.062 2.53-26.313-6.518-30.328-8.542-11.203-5.646-6.61 38.1-6.22 41.53M40.34 236c-8.423 1.026-11.847 6.411-3.686 9.745 5.528 2.259 21.323 5.899 32.117.257 5.869-3.068 9.216-8.72-3.422-10.002"
    />
  </svg>
);
