import { useNavigate } from "react-router";
import { SelectPanelButton } from "../SelectPanelButton/SelectPanelButton";
import styles from "./SelectPanel.module.scss";
import { WebAppRoutes } from "app/routes";

export const SelectPanel = (): JSX.Element => {
  const navigate = useNavigate();

  const navToCourses = () => {
    navigate(WebAppRoutes.HOME);
  };

  const navToAdminPanel = () => {
    window.location.href = window.location.origin + "/manager/local/fss/pages/courses.php";
  };

  return (
    <div className={styles["controls-wrapper"]}>
      <SelectPanelButton type="webapp" onClick={navToCourses} />
      <SelectPanelButton type="admin" onClick={navToAdminPanel} />
    </div>
  );
};
