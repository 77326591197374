import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EndPoints } from "../../../../../../app/endpoints";
import api from "../../../../../../services/api/api";
import { IQuizScore } from "./interface/quiz.interface";

const setQuizeStart = createAsyncThunk(
  "courseQuize/setQuizeStart",
  async (lesson_id: number, thunkAPI) => {
    const response = await api.post(EndPoints.QUIZE_START(lesson_id));
    return response.data;
  }
);

const setQuizeFinish = createAsyncThunk(
  "courseQuize/setQuizeFinish",
  async (lesson_id: string | undefined, thunkAPI) => {
    const response = await api.post(EndPoints.QUIZE_FINISH(lesson_id));
    return response.data;
  }
);

const getQuizeStatus = createAsyncThunk("courseQuize/getQuizeStatus", async (arg, thunkAPI) => {
  const response = await api.get(EndPoints.QUIZE_STATUS(arg));
  return response.data;
});

const getQuizeResult = createAsyncThunk(
  "courseQuize/getQuizeResult",
  async (lesson_id: string | number | undefined, thunkAPI) => {
    const response = await api.get(EndPoints.QUIZE_RESULTS(lesson_id));
    return response.data;
  }
);

const getQuize = createAsyncThunk(
  "courseQuize/getQuize",
  async (lesson_id: string | number | undefined, thunkAPI) => {
    const response = await api.get(EndPoints.QUIZE(lesson_id));
    return response.data;
  }
);

const getQuizScore = createAsyncThunk(
  "courseQuize/getQuizeScore",
  async (lesson_id: string | number | undefined, thunkAPI) => {
    const response = await api.get(EndPoints.QUIZE_SCORE(lesson_id));
    return response.data;
  }
);

const initialState = {
  isLoadingResults: false,
  attempt_id: null,
  isLoading: false,
  quze_state: "idle",
  navigation: "free",
  id: "",
  title: "",
  description: "",
  start_ts: null,
  end_ts: null,
  user_attempts: 0,
  time_to_complete: 0,
  time_spent: 0,
  position: 0,
  total_attempts: 0,
  scoring_attempt: "",
  passing_score: "last",
  access: null,
  type: "",
  attempt_status: "",
  preconditions: [],
  answered: [],
  review_options: {
    show_correct_answers: true,
  },
  results: [],
  score: {} as IQuizScore,
};

const courseQuizeSlice = createSlice({
  name: "courseQuize",
  initialState,
  reducers: {
    setQuizeIdle(state) {
      state.quze_state = "idle";
    },
    setQuizeStarted(state) {
      state.quze_state = "started";
    },
    setQuizeComplite(state) {
      state.quze_state = "complete";
    },
    clearAttemptStatus(state) {
      state.attempt_status = "";
    },
    clearScore(state) {
      state.score = {} as IQuizScore;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQuize.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getQuize.fulfilled, (state, action) => {
        state = Object.assign(state, action.payload.data);
        state.isLoading = false;
      })
      .addCase(getQuize.rejected, (state, action) => {
        state.isLoading = true;
      })
      .addCase(setQuizeStart.fulfilled, (state, action) => {
        courseQuizeSlice.caseReducers.setQuizeStarted(state);
      })
      .addCase(getQuizeResult.pending, (state, action) => {
        state.results = [];
        state.isLoadingResults = true;
      })
      .addCase(getQuizeResult.fulfilled, (state, action) => {
        if (action?.payload?.data?.attempts && Array.isArray(action?.payload?.data?.attempts)) {
          state.results = [...action.payload.data.attempts].sort((x: any, y: any) => {
            return new Date(x.start_ts) < new Date(y.start_ts) ? 1 : -1;
          }) as any;
          state.review_options = action.payload.data.review_options;
        }

        state.isLoadingResults = false;
      })
      .addCase(getQuizeResult.rejected, (state, action) => {
        state.results = [];
        state.isLoadingResults = false;
      })
      .addCase(getQuizeStatus.fulfilled, (state, { payload }) => {
        if (payload.data?.answered) {
          state.answered = payload.data.answered;
        }
      })
      .addCase(getQuizeStatus.rejected, (state, action) => {
        state.answered = [];
      })
      .addCase(getQuizScore.fulfilled, (state, action) => {
        state.score = action.payload.data;
      });
  },
});

export const { setQuizeIdle, setQuizeStarted, setQuizeComplite, clearAttemptStatus, clearScore } =
  courseQuizeSlice.actions;
export { getQuizScore, getQuize, getQuizeResult, getQuizeStatus, setQuizeFinish, setQuizeStart };
export default courseQuizeSlice.reducer;
