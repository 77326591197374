import { useSelector } from "react-redux";
import { TextMain } from "components/Typography/Texts";
import { IQuizeQuestionProp } from "pages/Course/components/Quiz/redux/Quze/interface/quiz.interface";
import {
  allAnswersSelector,
  selectCourseQuizeResultsReviewOptions,
} from "pages/Course/components/Quiz/redux/Quze/courseQuizeSelectors";
import styles from "./QuestTextResult.module.scss";
import { QuestResultAlert } from "../QuestResultAlert";
import { H3 } from "components/Typography/Titles";
import { CheckCircleOutlineOutlined } from "@mui/icons-material";
import { useAppSelector } from "hooks/redux";

export const QuestTextResult = ({
  title,
  description,
  questionsId,
  currentQuizeResult,
}: IQuizeQuestionProp) => {
  const reviewOptions = useAppSelector(selectCourseQuizeResultsReviewOptions);

  const allAnswers = useSelector(allAnswersSelector);

  const findedAnwer: any = allAnswers.find((answer: any) => answer.id === questionsId);

  return (
    <div className="course-questions-block">
      <H3 className="course-questions-block__title">{title}</H3>
      <div
        className="course-questions-block__descr"
        dangerouslySetInnerHTML={{ __html: description }}
      ></div>
      <div
        className="course-questions-block-wrapper"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <ul className="course-questions-block-list" style={{ minWidth: "60%" }}>
          <li
            className="course-questions-block-list__item course-questions-block-list__item--text"
            style={{ maxWidth: "100%" }}
          >
            <span className={"course-questions-bubble__item course-questions-bubble__item--text"}>
              {findedAnwer ? findedAnwer.answer : null}
            </span>
          </li>
        </ul>
        {reviewOptions.show_correct_answers && (
          <div className={styles["right-answers-wrapper"]}>
            <TextMain className="course-questions-block-wrapper__title">Правильный ответ:</TextMain>
            <div className={styles["right-answer"]}>
              <CheckCircleOutlineOutlined />
              <div>{(currentQuizeResult?.right as any)?.text}</div>
            </div>
          </div>
        )}
      </div>
      <QuestResultAlert
        right={
          currentQuizeResult?.status &&
          ["Correct", "Partially correct"].includes(currentQuizeResult?.status)
        }
        text={currentQuizeResult?.text || ""}
      />
    </div>
  );
};
