import { Capacitor } from "@capacitor/core";
import MainMenuDrawer from "Layout/PublicLayout/components/Drawer/MainMenuDrawer";
import UserWidget from "Layout/PublicLayout/components/UserWidget/UserWidget";
import Footer from "components/Footer/Footer";
import Hamburger from "components/Hamburger/Hamburger";
import { HeaderV2 } from "components/Header";
import { EducationStudioLogo, MyAcademyLogo } from "components/Logos";
import { RowNavMenu } from "components/RowNavMenu/RowNavMenu";
import { ScrollbarContainer } from "components/ScrollbarContainer";
import { useMediaQuery } from "hooks";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { getInitProfileFields } from "pages/Profile/redux/profile-slice/profile.slice";
import {
  newSelectAvatarIsLoading,
  selectProfileLoad,
} from "pages/Profile/redux/profile-slice/selectors/profile.selectors";
import { selectShopSettings } from "pages/Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import { CSSProperties, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { headerMenuData } from "../../app/constants";
import { AvatarLoadingOverlay } from "pages/Profile/components/AvatarLoadingOverlay";
import styles from "./Main.module.scss";

interface MainProps {
  children?: JSX.Element | JSX.Element[];
  sx?: CSSProperties;
}

export const Main = ({ children, sx }: MainProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const mob = useMediaQuery("(max-width: 769px)");
  const profileLoad = useAppSelector(selectProfileLoad);
  const shopSettings = useAppSelector(selectShopSettings);
  const avatarIsLoading = useAppSelector(newSelectAvatarIsLoading);

  const onCLickLogo = () => {
    navigate("/");
  };

  useEffect(() => {
    if (!profileLoad) {
      dispatch(getInitProfileFields());
    }
  }, [profileLoad, dispatch]);

  const headerData =
    shopSettings?.enable && !Capacitor.isNativePlatform()
      ? [
          ...headerMenuData,
          {
            label: "Магазин наград",
            path: "/rewards-shop",
          },
        ]
      : headerMenuData;

  return (
    <div className={styles["container"]}>
      <HeaderV2 className={styles["header"]}>
        <div className={styles["header-container"]}>
          <div className={styles["logo"]} onClick={onCLickLogo} role="button">
            {process.env.REACT_APP_THEME === "myAcademy" ? (
              <MyAcademyLogo />
            ) : (
              <EducationStudioLogo />
            )}
          </div>
          {!mob && (
            <>
              <RowNavMenu routes={headerData} className={styles["header-menu"]} />
              <UserWidget />
            </>
          )}
          {mob && <Hamburger />}
        </div>
      </HeaderV2>
      {mob && <MainMenuDrawer />}
      <main className={styles["main"]}>
        <ScrollbarContainer className={styles["scrollbar-container"]}>
          <div className={styles["content-container"]}>
            <div className={styles["content"]} style={sx}>
              {children}
            </div>
            {!Capacitor.isNativePlatform() && <Footer />}
          </div>
        </ScrollbarContainer>
      </main>
      {avatarIsLoading && <AvatarLoadingOverlay />}
    </div>
  );
};
