import { SVGProps } from "react";

export const EducationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.1198 6.12439C19.6065 4.95854 22.3933 4.95854 24.8801 6.12439L36.5897 11.6141C39.1368 12.8082 39.1368 16.9418 36.5897 18.136L24.8802 23.6256C22.3935 24.7915 19.6067 24.7915 17.1199 23.6256L5.4103 18.1359C2.86323 16.9418 2.86324 12.8082 5.41031 11.614L17.1198 6.12439Z"
      stroke="#C861F9"
      stroke-width="2.5"
    />
    <path d="M3.5 14.875V24.5" stroke="#C861F9" stroke-width="2.5" stroke-linecap="round" />
    <path
      d="M33.25 20.125V29.0945C33.25 30.8585 32.3689 32.5101 30.8257 33.3647C28.256 34.7878 24.143 36.75 21 36.75C17.857 36.75 13.744 34.7878 11.1743 33.3647C9.63113 32.5101 8.75 30.8585 8.75 29.0945V20.125"
      stroke="#C861F9"
      stroke-width="2.5"
      stroke-linecap="round"
    />
  </svg>
);
