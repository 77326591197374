import { numWord } from "app/utils";
import classNames from "classnames";
import { Badge } from "components/Badge";
import Container from "components/Container/Container";
import { Link } from "components/Link";
import { format, isBefore } from "date-fns";
import { normalizeISODate, normalizeTimestampDate } from "../../../../app/utils";

import Alert from "../../../../components/Alert/Alert";
import { CourseCoinList } from "../../../../components/CourseCoinList";
import { StarIcon } from "../../../../components/Icons";
import PaperBox from "../../../../components/Paper/PaperBox";
import { Skeleton } from "../../../../components/Skeleton";
import { H1 } from "../../../../components/Typography/Titles";
import { ICourse } from "../../../Course/redux/interfaces";
import { CourseFormat } from "../../../Course/redux/interfaces/course.interface";
import { EnrollButton } from "../LandingConversation/components/EnrollButton";
import { getBackgroundImage } from "./backgroundImage/getBackgroundImage.util";
import { useLandingMain } from "./useLandingMain";
import styles from "./LandingMain.module.scss";

interface LandingMainProps {
  course?: ICourse;
  loaded: boolean;
  isMulticourse: boolean;
}

export const LandingMain = ({ course, loaded, isMulticourse }: LandingMainProps) => {
  const {
    handleCourseEnter,
    handleCourseEnroll,
    courseDurationHours,
    courseDurationMinutes,
    coins,
    description,
    startDateIsShow,
    coinsIsShow,
    durationIsShow,
    coursePreviewSrc,
  } = useLandingMain({
    course,
  });

  const infoShowedCount = [
    startDateIsShow,
    !!course?.finished_ts,
    !!(course?.end_dt && isBefore(new Date(course?.end_dt), new Date()) && !course?.finished_ts),
    !!(course?.time_limit_total || course?.time_limit_actual),
    !!(course?.enrolled_ts && course?.time_limit_total),
    !!(course?.enrolled_ts && course.time_limit_total && course?.time_limit_actual === 0),
    durationIsShow,
  ].filter((item) => item);

  return (
    <div
      className={styles["main-wrapper"]}
      style={{
        backgroundImage: `url(${getBackgroundImage(course?.format)})`,
        backgroundColor: process.env.REACT_APP_THEME === "myAcademy" ? "transparent" : undefined,
      }}
    >
      <Container className={styles["landing-main-container"]}>
        {loaded ? (
          <PaperBox
            className={classNames("lan-main", styles["landing-main-container"])}
            style={{ overflow: "visible" }}
          >
            <div className="lan-main-content">
              {/* Рейтинг */}
              {course?.rating && (
                <Badge
                  color="accent"
                  className={classNames(styles["badge"], {
                    [styles["badge-myacademy"]]: process.env.REACT_APP_THEME === "myAcademy",
                  })}
                >
                  <StarIcon />
                  <p className={styles["badge-label"]}>{course.rating}</p>
                </Badge>
              )}
              <H1 className="lan-main-content__title">{course?.name}</H1>
              <p
                className={classNames("lan-main-content__descr", {
                  // eslint-disable-next-line
                  ["lan-main-content__descr-clip"]: description && description.length > 450,
                })}
              >
                {description}
              </p>
              {description && description.length > 450 && (
                <Link className="lan-main-content__more" href="#more">
                  Подробнее
                </Link>
              )}

              <EnrollButton
                course={course}
                handleCourseEnter={handleCourseEnter}
                handleCourseEnroll={handleCourseEnroll}
              />

              <div className={styles["info"]}>
                {startDateIsShow && (
                  <div className={styles["info-block"]}>
                    {course?.format === CourseFormat.meeting ? "Дата проведения" : "Старт обучения"}
                    :<span>{normalizeISODate(course?.start_dt)}</span>
                  </div>
                )}
                {course?.finished_ts && (
                  <div className={styles["info-block"]}>
                    Дата завершения:
                    <span>{normalizeTimestampDate(course?.finished_ts)}</span>
                  </div>
                )}
                {course?.end_dt &&
                  isBefore(new Date(course?.end_dt), new Date()) &&
                  !course?.finished_ts && (
                    <div className={styles["info-block"]}>
                      Окончание обучения:
                      <span>{format(new Date(course?.end_dt), "dd.MM.yyyy")}</span>
                    </div>
                  )}
                {(course?.time_limit_total || course?.time_limit_actual) && (
                  <div className={styles["info-block"]}>
                    Курс необходимо пройти за:{" "}
                    <span>
                      {!!course.time_limit_total &&
                        course.time_limit_total +
                          " " +
                          numWord(course.time_limit_total, ["день", "дня", "дней"])}{" "}
                      {!!course?.time_limit_actual &&
                        `(${numWord(course.time_limit_total, [
                          "остался",
                          "осталось",
                          "осталось",
                        ])} ${course?.time_limit_actual})`}
                    </span>
                  </div>
                )}
                {course?.enrolled_ts && course?.time_limit_total && (
                  <div className={styles["info-block"]}></div>
                )}
                {course?.enrolled_ts && course.time_limit_total && course?.time_limit_actual === 0 && (
                  <div className={styles["info-block"]}>
                    <div
                      className={classNames(
                        "lan-main-content-info",
                        styles["course-unavailable-alert"]
                      )}
                    >
                      <Alert>
                        Курс недоступен для прохождения после:{" "}
                        {format(
                          new Date(
                            (course?.enrolled_ts + course.time_limit_total * 3600 * 24) * 1000
                          ),
                          "dd.MM.yyyy"
                        )}
                        .{" "}
                        {process.env.REACT_APP_THEME === "myAcademy" ? (
                          <div>
                            Чтобы продлить доступ к курсу, напишите на{" "}
                            <a
                              href={"mailto:Education@lanit.ru"}
                              className="contacts-widget__link"
                              style={{ textDecoration: "underline" }}
                            >
                              Education@lanit.ru
                            </a>
                          </div>
                        ) : (
                          <div>
                            Чтобы продлить доступ к курсу, обратитесь к менеджеру организации.
                          </div>
                        )}
                      </Alert>
                    </div>
                  </div>
                )}

                {durationIsShow && (
                  <div className={styles["info-block"]}>
                    Длительность:
                    <span>
                      {!!courseDurationHours &&
                        `${courseDurationHours} ${numWord(courseDurationHours, [
                          "час",
                          "часа",
                          "часов",
                        ])} `}
                      {!!courseDurationMinutes &&
                        `${courseDurationMinutes} ${numWord(courseDurationMinutes, [
                          "минута",
                          "минуты",
                          "минут",
                        ])} `}
                    </span>
                  </div>
                )}

                {coinsIsShow && (
                  <div className={classNames(styles["info-block"])}>
                    За завершение:
                    <CourseCoinList
                      coins={coins}
                      isMulticourse={isMulticourse}
                      onLeft={(infoShowedCount.length || 0) % 2 === 0}
                    />
                  </div>
                )}
              </div>
            </div>

            <div
              className="lan-main-bg"
              style={{
                backgroundImage: course?.preview?.src ? `url(${course?.preview?.src})` : undefined,
              }}
            >
              <div className={"lan-main-bg__blur"}></div>
              <img className={"lan-main-bg__image"} src={coursePreviewSrc} alt="course preview" />
            </div>
          </PaperBox>
        ) : (
          <PaperBox className="lan-main">
            <div className="lan-main-content">
              <Skeleton variant="text" width={"100%"} height={95} />
              <br />
              <Skeleton variant="text" width={"100%"} height={24} />
              <Skeleton variant="text" width={"100%"} height={24} />
              <Skeleton variant="text" width={"100%"} height={24} />
            </div>
          </PaperBox>
        )}
      </Container>
    </div>
  );
};
