import { useMount } from "ahooks";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { WebAppRoutes } from "../../app/routes";
import { selectAuthOIDC } from "../../features/auth/selectors";
import { AuthorizationWindow } from "./components/AuthorizationWindow";
import classNames from "classnames";
import styles from "./Authorization.module.scss";
import { getCapabilities } from "features/auth/authSlice";
import { useAppDispatch } from "hooks/redux";

export const Authorization = () => {
  const authOIDC = useSelector(selectAuthOIDC);

  const dispatch = useAppDispatch();
  // const needReg: any = useSelector(selectRegRequirements);
  // const verifyError: any = useSelector(selectRegVerifyError);
  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    const htmlTag = document.getElementsByTagName("html")[0];
    console.log(htmlTag);

    htmlTag.style.padding = "0";

    return () => {
      htmlTag.style.padding = "";
    };
  }, []);

  // Проверка перехода на страницы только через навигацию приложения
  useEffect(() => {
    [
      WebAppRoutes.REGISTRATION,
      WebAppRoutes.REGISTRATION_CODE_CHECK,
      WebAppRoutes.REGISTRATION_SECOND_STEP,
      WebAppRoutes.SELECET_PANEL,
    ].includes(location.pathname as WebAppRoutes) &&
      !location.state?.is_allowed &&
      navigate("/webapp");
  }, [location, navigate]);

  useMount(() => {
    if (authOIDC?.idToken && location.pathname === WebAppRoutes.AUTH) {
      navigate(WebAppRoutes.HOME);
      dispatch(getCapabilities());
    }
  });

  if (location.pathname.includes(WebAppRoutes.REGISTRATION_EMAIL_BLOCKED) && !authOIDC?.email) {
    <Navigate to={WebAppRoutes.REGISTRATION_EMAIL_BLOCKED} state={{ from: location }} replace />;
  } else {
    <Navigate to={location.pathname as WebAppRoutes} state={{ from: location }} replace />;
  }

  return (
    <article
      className={classNames(styles["page"], {
        [styles["myacademy"]]: process.env.REACT_APP_THEME === "myAcademy",
      })}
    >
      <AuthorizationWindow />
    </article>
  );
};
