import { Capacitor } from "@capacitor/core";
import { getFileNameFromSource, getYouTubeIframe } from "app/utils";
import { useAppSelector } from "hooks/redux";
import { useMutationObservable } from "hooks/useMutationObservable";
import { lmsSettings } from "pages/Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import { useCallback, useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import Container from "../../../../components/Container/Container";
import { H1 } from "../../../../components/Typography/Titles";
import { CourseLessonText } from "../../redux/interfaces";
import { AchievementsListLesson } from "../AchievementsListLesson";
import CourseTestContentHeader from "../CourseTestContentHeader";
import { LessonCompetenciesList } from "../LessonCompetenciesList";
import styles from "./CourseText.module.scss";
import classNames from "classnames";

function wrapElement(el: any, wrapper: any) {
  el.parentNode.insertBefore(wrapper, el);
  wrapper.appendChild(el);
}

const CourseText = ({
  lesson,
  data,
  onDataLoaded,
  onEndReached,
}: {
  lesson: CourseLessonText;
  data: string;
  onDataLoaded?: (videos: NodeListOf<HTMLVideoElement> | undefined) => void;
  onEndReached?: () => void;
}) => {
  const [content, setCentent] = useState("");
  const [contentLoaded, setContentLoaded] = useState(false);
  const contentBlockRef = useRef<HTMLDivElement>(null);
  const selectedLmsSettings = useAppSelector(lmsSettings);

  const loaded = useCallback(() => {
    setContentLoaded(true);
    const videos = contentBlockRef.current?.querySelectorAll("video");
    onDataLoaded?.(videos);
  }, [onDataLoaded]);

  const observerIsListen = useMutationObservable(contentBlockRef.current, loaded);

  useEffect(() => {
    const parser = new DOMParser();
    const content = parser.parseFromString(data, "text/html");
    const videos = content.querySelectorAll("video");
    const links = content.querySelectorAll("a");

    // Удаляем <br>
    const brTags = content.querySelectorAll("br");
    brTags.forEach((br) => br.remove());

    try {
      links.forEach((linkItem) => {
        let isNodeText = false;
        const linkText = linkItem.innerText;
        const linkHref = linkItem.getAttribute("href");
        const fileExp = linkHref!.match(/\.[0-9a-z]{1,5}$/i);
        const fileName = linkText.substring(linkText.lastIndexOf("/") + 1);

        if (!linkItem.querySelectorAll("*").length && fileExp?.length && linkItem.innerText) {
          linkItem.innerText = decodeURI(fileName);
          if (linkItem.previousSibling && linkItem?.previousSibling?.nodeType === 3) {
            isNodeText = true;
          }
          if (!isNodeText) {
            const wrapper = document.createElement("span");
            wrapper.classList.add(styles["content-file-attached"]);
            if (fileExp) {
              wrapper.dataset.exp = fileExp[0].substring(1);
              linkItem.setAttribute("target", "_blank");
              linkItem.classList.add(styles["content-file-attached__link"]);
              wrapElement(linkItem, wrapper);
            }
          }
        }
      });

      videos.forEach((video) => {
        const src = video.querySelector("source")?.src;

        // Проверяем, является ли видео из YouTube
        const youTubeIframe = getYouTubeIframe(src);
        if (video.parentNode && youTubeIframe) {
          // Заменяем тег <video> на созданный <iframe>
          video.parentNode.replaceChild(youTubeIframe, video);
        }

        const name = getFileNameFromSource(src);
        if (!name) return;

        video.setAttribute("name", name);
      });

      setCentent(content.body.innerHTML);
    } catch (e) {
      setCentent(data);
    }
  }, [setCentent, data]);

  const { ref: observerTarget, inView } = useInView({
    threshold: 0.2,
    triggerOnce: false,
    delay: 200,
  });

  useEffect(() => {
    if (!inView) return;
    onEndReached?.();
  }, [inView, onEndReached]);

  const isEnableCompetenciesInEsMobile =
    selectedLmsSettings.competences &&
    Capacitor.isNativePlatform() &&
    process.env.REACT_APP_THEME === "base";

  return (
    <Container>
      <div className=" course-test-block">
        <div className="course-test-content course-test-content_page">
          <CourseTestContentHeader
            status={lesson.status}
            lessonType={lesson.type}
            coins={lesson.coins}
          >
            <H1 className="course-test-top__title">{lesson.title}</H1>
          </CourseTestContentHeader>

          <AchievementsListLesson />
          {(selectedLmsSettings.competences || isEnableCompetenciesInEsMobile) && (
            <LessonCompetenciesList id={lesson.id} />
          )}
          <div className="course-text__content">
            <div
              ref={contentBlockRef}
              className={classNames("editable", styles["editable-content"])}
              dangerouslySetInnerHTML={observerIsListen ? { __html: content } : undefined}
            ></div>
            {contentLoaded && <div ref={observerTarget}></div>}
          </div>
        </div>
      </div>
    </Container>
  );
};
export default CourseText;
