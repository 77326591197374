import { generateUid } from "app/utils";
import { ScoringAttemptType } from "../../redux/Quze/interface";
import QuizeAttemptsInfo from "../QuizeAttemptsInfo";

import { getQuizResultsForRendering } from "./functions";
import { IQuizResultAnswers } from "../../redux/Quze/interface/quiz.interface";

interface QuizResultsProps {
  results?: IQuizResultAnswers[];
  scoringAttempt?: ScoringAttemptType;
}

export const QuizResults = ({ results = [], scoringAttempt = "last" }: QuizResultsProps) => {
  const resultForRendering = getQuizResultsForRendering([...results], scoringAttempt);
  return (
    <div className="course-test-achieve">
      {resultForRendering?.map((quizResult, index) => (
        <QuizeAttemptsInfo
          key={generateUid()}
          first={!index && scoringAttempt === "highter"}
          currentPoints={quizResult.attempt_received_score}
          totalPoints={quizResult.attempt_max_score}
          rightQuestions={quizResult.right?.length}
          totalQuestions={quizResult.total_questions}
          percent={quizResult.score_percentage_correct || quizResult.percentage_correct}
          endTs={quizResult.end_ts}
          isCalculatedResult={quizResult.answered === null}
        />
      ))}
    </div>
  );
};
