export const CatAvatarSvg = () => {
  return (
    <svg
      width="234"
      height="234"
      viewBox="0 0 234 234"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_525_4673)">
        <rect width="234" height="234" rx="117" fill="#7D6BF2" />
        <path
          d="M189.427 90.9625C201.724 97.0733 205.923 103.122 206.577 108.411C207.536 117.312 205.457 124.464 200.498 136.225C192.501 161 180.372 206.146 164.002 234.5C-15.7306 234.5 -93.7974 233.723 -90.7998 233.723C-86.3034 233.723 -53.8018 145.715 60.8872 136.771C64.1823 136.509 67.3816 136.343 70.488 136.266C71.1893 133.347 72.3943 130.712 74.582 126.727C69.7764 114.949 66.0768 87.343 64.8212 75.8473C64.8212 75.3748 64.9825 74.9023 65.1437 74.4298C65.7888 73.1699 67.5628 72.5399 69.0143 73.1699L105.319 90.9624C111.518 88.6948 118.077 86.6329 124.995 84.571C131.546 82.5681 138.097 80.8611 144.258 79.2945L157.255 45.5236C157.734 44.2309 159.333 43.4229 160.611 44.0693C161.091 44.2309 161.41 44.5541 161.73 45.0389C168.457 54.5292 184.983 78.7275 189.427 90.9625Z"
          fill="#FFCB3D"
        />
        <path
          opacity="0.2"
          d="M-97.6901 222.882C-99.0433 214.321 -95.4329 209.111 -86.8588 207.253C-51.4975 205.296 -20.0331 207.92 7.53427 215.127C35.1017 222.334 51.157 228.625 55.7003 234.001H-86.8588C-92.9116 234.001 -97.6901 229.096 -97.6901 222.882ZM183.154 164.962C187.277 160.184 199.962 139.639 199.962 139.639C199.962 139.639 186.008 182.003 177.604 208.282C178.08 206.053 171.42 223.89 170.628 219.272V219.112C169.518 213.857 165.237 210.034 159.845 209.716C154.771 209.397 149.38 209.238 143.672 209.238C136.378 209.238 128.926 209.716 121.632 210.671L111.008 178.34C121.315 183.596 133.048 185.507 144.465 184.552C148.429 184.233 152.234 183.437 156.04 182.322C166.505 179.296 175.86 173.085 183.154 164.962Z"
          fill="#43375D"
        />
        <path
          d="M-97.6901 222.881C-99.0433 214.319 -95.4329 209.11 -86.8588 207.252C-51.4975 205.294 -20.0331 207.919 7.53427 215.125C35.1017 222.332 51.157 228.624 55.7003 234H-86.8588C-92.9116 234 -97.6901 229.094 -97.6901 222.881ZM145.01 125.868C147.26 128.389 150.926 129.26 154.104 127.823C156.995 126.14 158.529 122.885 157.917 119.576C157.659 118.183 158.58 116.844 159.973 116.586C161.367 116.329 162.705 117.249 162.963 118.643C163.983 124.156 161.381 129.603 156.544 132.34L156.367 132.431C152.067 134.44 147.216 133.881 143.539 131.36C142.391 134.116 140.103 136.472 137.021 137.865C130.825 140.219 123.879 137.901 120.754 132.427C120.052 131.197 120.48 129.629 121.711 128.927C122.941 128.224 124.508 128.652 125.211 129.883C127.058 133.118 131.32 134.541 135.051 133.129C137.876 131.849 139.491 129.232 139.199 126.668C139.19 126.652 139.182 126.635 139.173 126.619C138.43 125.328 138.282 123.713 138.43 122.261C138.579 121.131 139.173 120.162 139.916 119.678C141.401 118.548 143.629 119.194 144.521 120.808C145.307 122.362 145.371 124.253 145.01 125.868ZM101.676 125.94C103.479 129.33 101.826 134.172 100.023 134.495C98.6703 134.656 97.0173 132.881 96.4162 131.751C95.6648 130.46 95.5146 128.845 95.6648 127.393C95.8151 126.263 96.4162 125.294 97.1676 124.81C98.6703 123.68 100.774 124.326 101.676 125.94ZM178.714 98.5696C180.383 101.959 178.866 106.479 177.045 107.125C175.679 107.286 174.01 105.51 173.403 104.381C172.644 103.089 172.493 101.475 172.644 100.022C172.796 98.8924 173.403 97.9239 174.162 97.4397C175.679 96.3098 177.804 96.9554 178.714 98.5696Z"
          fill="#43375D"
        />
      </g>
      <defs>
        <clipPath id="clip0_525_4673">
          <rect width="234" height="234" rx="117" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
