import { SVGProps } from "react";
export const TreeInPotSvgImage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={101}
    height={235}
    viewBox="0 0 101 235"
    fill="none"
    {...props}
  >
    <path
      stroke="#191229"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M67.972 160.731c-11.059 5.02-37.824 6.133-49.317-5.092 20.54-11.019 50.104-4.708 58.368-3.669 8.265 1.039 4.556 19.716 0 38.957-5.165 21.815-7.231 25.97-25.31 25.97s-20.253-5.761-26.344-25.97c-5.165-17.142-6.715-23.154-6.715-23.154"
    />
    <path
      stroke="#191229"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M29.525 151.819C18.398 141.367-10.003 94.524 8.628 80.806c9.264-6.82 26.09 6.628 25.756 15.394-.333 8.765-17.578-13.985-16.56-40.754C18.896 27.271 34.368-3.008 48.892 3.324c18.894 8.237 27.431 55.26 12.088 69.103-15.344 13.842 9.476-24.985 24.343-11.884 13.25 11.675-3.798 51.181-19.428 58.116-15.63 6.934-12.26-9.193 19.214-14.275 25.4-4.101 1.51 36.047-19.214 45.116M25.794 206.701c-16.609 2.048-23.358 12.799-7.267 19.455 10.9 4.51 42.043 11.776 63.326.512 11.572-6.124 18.172-17.407-6.748-19.967"
    />
  </svg>
);
