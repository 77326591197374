import { Tooltip } from "components/Tooltip";
import { CourseCoinsData } from "../../pages/Course/redux/interfaces/course.interface";
import { CourseCoinAmount } from "../CourseCoinAmount";
import styles from "./CourseCoinList.module.scss";
import classNames from "classnames";

interface CourseCoinListProps {
  coins?: CourseCoinsData;
  isMulticourse: boolean;
  onLeft?: boolean;
}

export const CourseCoinList = ({ coins, isMulticourse, onLeft }: CourseCoinListProps) => (
  <div className={styles["coin-list"]}>
    <Tooltip
      content="За завершение данного курса"
      className={classNames(styles["tooltip"], { [styles["tooltip-on_left"]]: onLeft })}
    >
      <CourseCoinAmount coin={coins?.course} hasText={false} />
    </Tooltip>

    {!isMulticourse && !!coins?.lessons?.amount && (
      <>
        {coins?.course?.amount && <span className={styles["plus"]}>+</span>}

        <Tooltip
          content="За завершение всех уроков курса"
          className={classNames(styles["tooltip"], { [styles["tooltip-on_left"]]: onLeft })}
        >
          <CourseCoinAmount coin={coins?.lessons} hasText={false} />
        </Tooltip>
      </>
    )}

    {isMulticourse && !!coins?.linked_courses?.amount && (
      <>
        {coins?.course?.amount && <span className={styles["plus"]}>+</span>}

        <Tooltip
          content="За завершение всех курсов траектории"
          className={classNames(styles["tooltip"], { [styles["tooltip-on_left"]]: onLeft })}
        >
          <CourseCoinAmount coin={coins?.linked_courses} hasText={false} />
        </Tooltip>
      </>
    )}
  </div>
);
