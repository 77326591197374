import { RootState } from "app/store";
import { ScoringAttemptType } from "./interface";
import {
  IQuizResultAnswers,
  IQuizResultReviewOptions,
  QuizAccess,
} from "./interface/quiz.interface";

export const selectCourseQuizeDetail = (state: RootState) => {
  return state.courseQuize;
};
export const selectCourseQuizeIsLoading = (state: RootState) => {
  return state.courseQuize.isLoading;
};

export const selectCourseIsLoadingQuizeResults = (state: RootState) => {
  return state.courseQuize?.isLoadingResults;
};

export const selectCourseQuizeResultsReviewOptions = (state: RootState) => {
  return state.courseQuize?.review_options as IQuizResultReviewOptions;
};

export const selectCourseQuizeResultsAttempts = (state: RootState) => {
  return state.courseQuize?.results as IQuizResultAnswers[];
};

export const selectCourseScoringAttempt = (state: RootState) => {
  return state.courseQuize?.scoring_attempt as ScoringAttemptType;
};

export const selectCoursePassingScore = (state: RootState) => {
  return state.courseQuize?.passing_score as string;
};

export const selectCourseQuizAccess = (state: RootState) => {
  return state.courseQuize?.access as QuizAccess[] | null;
};

export const selectCourseQuizeStatus = (state: RootState) => {
  return state.courseQuize.quze_state;
};

export const selectCoursePosition = (state: RootState) => {
  return state.courseQuize.position;
};

export const selectCourseNavigation = (state: RootState) => {
  return state.courseQuize.navigation;
};

export const selectCourseAnswersOfAttempt = (state: RootState) => {
  return state.courseQuizeQuestions.answersOfAttempt;
};

export const answersByPageSelector = (state: RootState) => state.courseQuizeQuestions.answersByPage;

export const allAnswersSelector = (state: RootState) => state.courseQuizeQuestions.allAnswers;

export const selectCurrentOpenPageInQuiz = (state: RootState) =>
  state.courseQuizeQuestions.currentOpenPageInQuiz;

export const selectCourseQuizeAttemptStatus = (state: RootState) => {
  if (state.courseQuize.total_attempts === 0) {
    return true;
  }
  if (state.courseQuize.user_attempts >= state.courseQuize.total_attempts) {
    return false;
  }
  return true;
};

export const selectCourseQuizeTotalAttempts = (state: RootState) =>
  state.courseQuize.total_attempts;

export const selectCourseQuizeAttemptsLeft = (state: RootState) => {
  const attempts = state.courseQuize.total_attempts - state.courseQuize.user_attempts;
  return attempts > 0 ? attempts : 0;
};

export const selectCourseQuizeIsStarted = (state: RootState) => {
  return !!state.courseQuize.start_ts;
};

export const selectCourseQuizeAnswered = (state: RootState) => {
  return state.courseQuize.answered;
};

export const selectCourseScore = (state: RootState) => {
  return state.courseQuize.score;
};

export const selectAnswerIsLoading = (state: RootState) => {
  return state.courseQuizeQuestions.answerIsLoading;
};
