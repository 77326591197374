import { ArrowDownIcon, CheckIcon } from "components/Icons";
import { Subtitle } from "components/Typography/Titles";
import { useAppSelector } from "hooks/redux";
import { selectFormats } from "pages/Courses/redux/coursesListSliceSelectors";
import { useState } from "react";

interface CourseSearchPopupFromatsPops {
  activeFormats: string[];
  setActiveFormats: (format: string[]) => void;
}

export const CourseSearchPopupFromats = ({
  activeFormats,
  setActiveFormats,
}: CourseSearchPopupFromatsPops): JSX.Element => {
  const formats = useAppSelector(selectFormats);

  const [isOpenOptions, setIsOpenOptions] = useState(false);

  const onClickFormat = (value: any) => () => {
    setActiveFormats(
      activeFormats?.includes(value)
        ? activeFormats?.filter((item) => item !== value)
        : [...(activeFormats ?? []), value]
    );
  };

  const handleToggleOptions = () => setIsOpenOptions((prev) => !prev);

  return (
    <div className="course-search-popup__formats">
      <Subtitle onClick={handleToggleOptions} className="course-search-popup__subtitle">
        <span>Формат</span>
        <div
          className="course-search-popup__subtitle-button"
          style={{ transform: `scale(${isOpenOptions ? "-1, 1" : "1, -1"})` }}
        >
          <ArrowDownIcon />
        </div>
      </Subtitle>
      {isOpenOptions && (
        <>
          {([...formats] || [])
            .sort((a, b) => a.label.localeCompare(b.label))
            .map(({ label, value }) => {
              const isActive = (activeFormats ?? []).includes(value);

              return (
                <li
                  key={value}
                  className={
                    isActive
                      ? "course-search-popup-list__item course-search-popup-list__item--active"
                      : "course-search-popup-list__item"
                  }
                  onClick={onClickFormat(value)}
                >
                  {isActive && <CheckIcon className="course-search-popup-list__icon" />}
                  <span>{label}</span>
                </li>
              );
            })}
        </>
      )}
    </div>
  );
};
