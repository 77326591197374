import { H3 } from "../../../../../../../../../components/Typography/Titles";
import { TextMain } from "../../../../../../../../../components/Typography/Texts";
import { QuestResultAlert } from "../QuestResultAlert";
import {
  IQuizQuestionOption,
  IQuizeQuestionProp,
} from "../../../../../redux/Quze/interface/quiz.interface";
import classNames from "classnames";
import { CheckCircleOutlineOutlined } from "@mui/icons-material";
import styles from "./QuestRadioResult.module.scss";
import { generateUid } from "app/utils";
import { useAppSelector } from "hooks/redux";
import { selectCourseQuizeResultsReviewOptions } from "pages/Course/components/Quiz/redux/Quze/courseQuizeSelectors";

export const QuestRadioResult = ({
  title,
  description,
  multy,
  questions = [],
  currentQuizeResult,
}: IQuizeQuestionProp) => {
  const reviewOptions = useAppSelector(selectCourseQuizeResultsReviewOptions);

  const getRightWrongAnswerClass = (item: IQuizQuestionOption) => {
    if (currentQuizeResult?.status.toLocaleLowerCase() === "not answered") {
      return "";
    }

    if (multy) {
      const isRightWrang = currentQuizeResult?.selected?.some((select) => select.code === item.code)
        ? currentQuizeResult?.right?.some((i) => i.code === item.code)
          ? 1
          : -1
        : 0;

      return isRightWrang > 0
        ? " course-questions-bubble__item--right"
        : isRightWrang < 0
        ? " course-questions-bubble__item--misstake"
        : "";
    }

    const isSelectedAnswer = currentQuizeResult?.selected?.find(
      (select) => select.code === item.code
    );

    if (!isSelectedAnswer) {
      return "";
    }

    const isCorrectAnswer = currentQuizeResult?.status.toLocaleLowerCase() === "correct";

    return isSelectedAnswer && isCorrectAnswer
      ? " course-questions-bubble__item--right"
      : " course-questions-bubble__item--misstake";
  };

  return (
    <div className="course-questions-block">
      <H3 className="course-questions-block__title">{title}</H3>
      <div
        className="text-main course-test__descr editable"
        dangerouslySetInnerHTML={{ __html: description }}
      ></div>

      <div
        className="course-questions-block-wrapper"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div>
          {multy ? (
            <TextMain className="course-questions-block-wrapper__title">
              Выберите правильные ответы:
            </TextMain>
          ) : (
            <TextMain className="course-questions-block-wrapper__title">
              Выберите один из ответов:
            </TextMain>
          )}
          <ul className="course-questions-block-list">
            {questions.map((item) => (
              <li key={item.code} className={"course-questions-block-list__item"}>
                <span className={"course-questions-bubble__item" + getRightWrongAnswerClass(item)}>
                  {item.text}
                </span>
              </li>
            ))}
          </ul>
        </div>
        {reviewOptions.show_correct_answers &&
          !!currentQuizeResult?.right &&
          !!currentQuizeResult.right.length && (
            <div className={styles["right-answers-wrapper"]}>
              <TextMain
                className={classNames(
                  "course-questions-block-wrapper__title",
                  styles["right-answers-wrapper-title"]
                )}
              >
                Правильный ответ:
              </TextMain>

              <div>
                {currentQuizeResult?.right.map((item) => (
                  <div className={styles["right-answer"]} key={generateUid()}>
                    <CheckCircleOutlineOutlined />
                    {item.text}
                  </div>
                ))}
              </div>
            </div>
          )}
      </div>
      <QuestResultAlert
        right={
          currentQuizeResult?.status &&
          ["Correct", "Partially correct"].includes(currentQuizeResult?.status)
        }
        text={currentQuizeResult?.text || ""}
        isSelect={true}
      />
    </div>
  );
};
