import { Skeleton } from "components/Skeleton";
import { useGetAvatarQuery } from "app/store";
import styles from "./UserAvatar.module.scss";
import esAvatars from "../../assets/avatars/education-studio-avatars.json";
import classNames from "classnames";

const UserAvatar = ({ className }: { className?: string }) => {
  const { data, isLoading } = useGetAvatarQuery();

  return isLoading ? (
    <Skeleton width={80} height={80} variant="circular" className={className} />
  ) : (
    <div className={classNames(styles["avatar-container"], className)}>
      <img src={data?.path || esAvatars.defaultAvatar} alt="avatar" className={styles["avatar"]} />
    </div>
  );
};

export default UserAvatar;
