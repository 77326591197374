import { SVGProps } from "react";
export const FloorLampSvgImage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={261}
    height={300}
    viewBox="0 0 261 300"
    fill="none"
    {...props}
  >
    <path
      stroke="#191229"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M250.882 295.409c-8.637 3.647-40.842 2.987-46.346-1.661-8.248-6.965 14.536-14.831 26.315-13.927 12.963.996 30.243 11.276 20.031 15.588ZM230.529 279.75c8.835-14.864 20.08-74.321 15.261-115.536C240.97 123 217.275 55.1 134.948 24.694c-19.273-7.12-57.87-14.527-72.332-14.527-4.01 0-16.476-.16-21.728.176 6.034-5.47 14.852-13.577 29.703.479 12.048 8.425 29.022 16.574 36.251 34.817 7.229 18.242-94.378 21.42-98.394 3.854C4.432 31.926 30.575 16.343 36.2 14.178M25.096 65.25l-16.061 15M56.94 67.517l-.244 17.472M89.842 64.125l14.912 14.586"
    />
  </svg>
);
