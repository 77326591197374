import { SVGProps } from "react";
export const FlowerSvgImage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={158}
    height={251}
    viewBox="0 0 158 251"
    fill="none"
    {...props}
  >
    <path
      stroke="#191229"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M93.984 183.367c-10.001 4.525-34.206 5.529-44.6-4.591 18.576-9.933 45.31-4.244 52.785-3.308 7.473.937 4.12 17.774 0 35.12C97.498 230.254 95.629 234 79.279 234c-16.348 0-18.315-5.194-23.822-23.412-4.671-15.453-6.072-20.874-6.072-20.874"
    />
    <path
      stroke="#191229"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M73.588 173c-.955-1.756-2.14-21.009-2.14-28.967-13.698 4.096-36.501 4.266-43.518-13.29 19.295-7.022 29.235 1.171 43.518 2.926-1.36-5.464 0-31.79 0-36.893-6.802 1.366-22.469 1.202-37.086-4.65C19.824 86.306 4.428 68.911 2.204 60.35 24.164 49.326 55.46 79.96 75.076 88.58c2.268-5.009-12.361-15.83-25.512-34.497-13.15-18.669-7.591-53.836-4.87-52.015 2.72 1.821 13.15 4.553 23.126 33.239 9.976 28.685 4.535 30.051 3.628 25.043-.907-5.009 1.814-15.938 9.07-30.053 7.255-14.115 23.126-28.23 24.486-26.408 1.86 26.408-11.252 44.465-17.685 53.242-4.724 6.446-7.326 24.009 0 15.057 17.685-21.61 39.425-30.052 68.473-30.052-6.802 16.049-26.754 32.328-46.253 40.524-19.499 8.196-25.394 1.822-19.046 2.732 6.349.91 87.519 23.678 58.497 28.23-29.021 4.553-47.544-11.733-54.8-15.376C73.949 88.083 82.247 166.825 82.954 173M55.3 225c-15.1 1.894-21.236 11.836-6.607 17.991 9.91 4.17 38.223 10.889 57.572.473 10.52-5.662 16.52-16.097-6.134-18.464"
    />
  </svg>
);
