import { SVGProps } from "react";

export const TrainingIllustration = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 253"
    width={300}
    height={253}
    fill="none"
    {...props}
  >
    <rect
      width={160}
      height={160}
      x={91.411}
      y={17}
      fill="#F0EEFF"
      rx={20}
      transform="rotate(15 91.411 17)"
    />
    <path
      stroke="#191229"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M240.205 51.455c-6.349 6.2-9.175 13.319-9.795 16.103M220.896 41.182c-3.139 7.317-3.052 14.334-2.616 16.927M203.115 36c-.849 8.833 1.584 16.095 2.908 18.622"
    />
    <path
      fill="#E5B0FF"
      stroke="#191229"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M235.927 193.065c-29.156 12.369-42.85 20.762-51.243 23.854-8.394 3.093-16.787 0-29.156-5.301s-61.08-35.539-70.975-41.585c-7.952-4.859-7.918-10.962 0-15.461 19.436-11.044 56.28-21.796 58.606-25.119 3.092-4.418 6.184-77.748 12.369-79.515 6.185-1.767 97.627 35.34 102.045 42.85 4.417 7.509-7.51 83.491-14.578 85.699-.913.286-1.723.431-2.436.467 1.515 4.673 1.612 11.395-4.632 14.111Z"
    />
    <path
      fill="#fff"
      d="M148.167 139.107c-6.185-.883-20.762 6.071-26.064 9.28-8.835 5.347 15.462 19.879 22.971 24.296 16.345 9.615 32.248 20.759 43.292 19.437 5.263-.63 27.386-12.81 23.852-16.786-3.534-3.976-57.867-35.343-64.051-36.227ZM138.446 193.442c5.301 2.651 18.553-4.353 13.694-7.948-5.524-4.087-16.786-11.485-20.762-12.811-3.976-1.325-15.711 4.473-12.369 8.394 3.389 3.975 14.136 9.715 19.437 12.365Z"
    />
    <path
      stroke="#191229"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M148.167 139.107c-6.185-.883-20.762 6.071-26.064 9.28-8.835 5.347 15.462 19.879 22.971 24.296 16.345 9.615 32.248 20.759 43.292 19.437 5.263-.63 27.386-12.81 23.852-16.786-3.534-3.976-57.867-35.343-64.051-36.227ZM138.446 193.442c5.301 2.651 18.553-4.353 13.694-7.948-5.524-4.087-16.786-11.485-20.762-12.811-3.976-1.325-15.711 4.473-12.369 8.394 3.389 3.975 14.136 9.715 19.437 12.365ZM232.097 185.936c-5.743 3.975-46.383 22.529-51.243 23.854-4.859 1.325-10.602 0-18.553-3.092"
    />
    <path
      fill="#fff"
      stroke="#191229"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M223.262 167.824c-8.835-3.092-66.461-35.115-71.762-37.324 0-1.767 6.85-70.5 9.5-70.5 2.65 0 87 34.052 87.442 38.911.442 4.86-7.51 49.035-10.16 58.311-2.651 9.277-6.185 13.695-15.02 10.602Z"
    />
    <path
      fill="#fff"
      d="M98.315 86.776C85.968 78.026 65.742 81.568 59 88.194c1.72 14.53 2.428 29.317 4.86 43.733 11.485 6.626 30.286.768 30.286-1.855 0-4.841.604-12.104 1.475-15.458 4.831 1.816 11.913 1.09 12.396-6.657.484-7.746-9.644-10.893-12.396-4.841 0-3.909 2.694-16.34 2.694-16.34Z"
    />
    <path
      stroke="#191229"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M94.146 89.077c-5.726 3.96-26.753 4.86-35.146-.883m0 0c6.742-6.626 26.968-10.168 39.315-1.418 0 0-2.694 12.431-2.694 16.34 2.752-6.052 12.88-2.905 12.396 4.841-.483 7.747-7.565 8.473-12.396 6.657-.871 3.354-1.475 10.617-1.475 15.458 0 2.623-18.801 8.481-30.287 1.855-2.43-14.416-3.138-29.203-4.859-43.733ZM69.602 49.13c7.828-2.938 12.91.28 13.872 4.757.961 4.477-.687 6.995-5.22 10.073-4.532 3.078-7.554 8.395-3.159 12.312M89.04 61.694c.441 3.976-1.871 6.374-4.31 7.529-2.758 1.306-6.732 3.525-3.366 7.05"
    />
  </svg>
);
